<header class="header">
  <nav class="navbar navbar-expand-md px-4 d-flex justify-content-between">

    <div class="d-flex align-items-center h-100 me-2">
      <a href="{{ url }}">
        <h1>
          <img alt="Opale" src="/assets/images/logo.png" class="logo"/>
        </h1>
      </a>
    </div>


    <ul class="ms-auto list-unstyled m-0 d-flex align-items-center nav-right">

      <div class="nav-item d-none d-lg-block ms-3">
        <a href="/login" class="btn btn-primary rounded-pill px-4 py-2 shadow h3 fw-bolder" *ngIf="!login" translate>
          Se connecter
        </a>
        <a (click)="authSrv.removeAuth()" class="btn btn-primary rounded-pill px-4 py-2 shadow h3 fw-bolder d-flex align-items-center" *ngIf="login" translate>
          <span class="material-icons icon-sm text-center me-1">power_settings_new</span>
          <span class="white-space-normal" translate>Se déconnecter</span>
        </a>

      </div>

      <li class="nav-item {#d-lg-none#} d-none ms-3">
        <a class="nav-link p-0 pointer" role="button" data-toggle="modal" data-target="#rightMenuModal">
          <div class="d-flex align-items-center justify-content-center">
            <span class="material-icons menu-icon">menu</span>
          </div>
        </a>
      </li>

    </ul>

  </nav>
</header>
