import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  standalone: true,
  imports: [],
  templateUrl: './progress-bar.component.html',
  styleUrl: './progress-bar.component.scss'
})
export class ProgressBarComponent {

  @Input() percent?: number;

  getPercent(): string {
    if (!this.percent) {
      return 0 + '%';
    } else {
      return this.percent + '%';
    }
  }

}
