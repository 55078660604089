<div class="modal-header d-flex align-items-center justify-content-between">
  <h4 class="modal-title" translate>Inscription</h4>
  <button type="button" class="btn btn-primary rounded-circle d-flex align-items-center justify-content-center btn-close" aria-label="Close" (click)="closeModal()">
    <span class="material-icons-outlined text-white fw-bold m-auto">close</span>
  </button>
</div>
<div class="modal-body">

  <p>Nous générons aléatoirement un identifiant pour préserver ton anonymat. Mémorise simplement ce login et ton mot de passe pour accéder à tes résultats ultérieurement.</p>

  <form [formGroup]="form">

    <!-- password -->
    <div class="form-group mb-3">
      <label class="text-md text-primary fw-bolder" for="password" translate>Mot de passe</label>
      <input type="password" id="password" formControlName="password" class="form-control bg-white-gradient shadow-internal light-rounded">
    </div>

    <!-- password confirm -->
    <div class="form-group mb-3">
      <label class="text-md text-primary fw-bolder" for="passwordConfirm" translate>Confirmation mot de passe</label>
      <input type="password" id="passwordConfirm" formControlName="passwordConfirm" class="form-control bg-white-gradient shadow-internal light-rounded" (change)="samePassword()">
      <span class="badge bg-danger mt-1" *ngIf="errorConfirmPassword">La confirmation du mot de passe doit être identique au mot de passe.</span>
    </div>

    <button type="submit" class="btn btn-primary rounded-pill" [disabled]="formIsInvalid()" (click)="submit()" translate>S'inscrire</button>

  </form>
</div>
