import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from "./pages/home/home.component";
import {AnswersComponent} from "./pages/answers/answers.component";
import {LoginComponent} from "./pages/login/login.component";

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'answers', component: AnswersComponent},
  {path: 'login', component: LoginComponent},
  {
    path: '**',
    redirectTo: '/'
  }
];

export const AppRoutingModule = RouterModule.forRoot(routes);
