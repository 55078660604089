import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ToolsService} from "../tools/tools.service";
import {Observable, Subject} from "rxjs";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  signInEvent = new Subject<any>();

  constructor(
    private http: HttpClient,
    private toolSrv: ToolsService,
    private router: Router
  ) { }

  login(credentials: any): Observable<any> {
    return this.http.post<any>(`${this.toolSrv.getApiEndpoint()}auth/signin`, credentials);
  }

  signup(credentials: any): Observable<any> {
    return this.http.post<any>(`${this.toolSrv.getApiEndpoint()}auth/signup`, credentials);
  }

  saveToken(token: string): void {
    sessionStorage.setItem('token', token);
    this.signInEvent.next(true);
  }

  getToken(): string | null {
    return sessionStorage.getItem('token');
  }

  async removeAuth(): Promise<void> {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('login');
    sessionStorage.removeItem('survey');

    await this.router.navigate(['/']);

    this.signInEvent.next(false);
  }

  saveLogin(login: string): void {
    sessionStorage.setItem('login', login);
  }

  getLogin(): string | null {
    return sessionStorage.getItem('login');
  }

  isLogged(): boolean {
    const token = sessionStorage.getItem('login');
    if (!token) {
      return false;
    }
    return true;
  }
}
