import { Component } from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-info-modal',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './info-modal.component.html',
  styleUrl: './info-modal.component.scss'
})
export class InfoModalComponent {

  message!: string;
  title!: string;

  constructor(private modalRef: BsModalRef) { }

  closeModal(): void {
    this.modalRef.hide();
  }
}
