import {Injectable, Injector} from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {AuthService} from '../services/auth/auth.service';
import {from} from 'rxjs';
import {AlertService} from '../services/alert/alert.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor(
    private injector: Injector,
    private alertSrv: AlertService,
    private authSrv: AuthService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(req, next));
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {

    // add headers
    req = req.clone({headers: req.headers.set('Content-Type', 'application/json')});
    req = req.clone({headers: req.headers.set('Accept', 'application/json')});
    req = req.clone({headers: req.headers.set('ApiKey', environment.api.publicKey)});

    const token = this.authSrv.getToken();
    if (token) {
      req = req.clone({headers: req.headers.set('Authorization', `Bearer ${token}`)});
    }

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (!environment.production) {
            if (err.message) {
              console.error(`Opale: Error decoding token: ${err.message}`);
            } else {
              console.error(err);
            }
          }

          // Affiche une alerte d'erreur
          this.alertSrv.error(err.error.message);
        }

        return of(err);
      })
    ).toPromise();



  }

}
