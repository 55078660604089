<section class="mb-5 container pt-5">
  <div class="row">
    <div class="col-12 col-lg-6">
      <div class="d-flex flex-column-reverse flex-lg-column justify-content-between h-100">
        <div>
          <div class="d-flex flex-wrap justify-content-between my-4 my-lg-0">
            <div class="w-lg-40 d-flex justify-content-start m-2">
              <a href="mailto:contact@opale.care"><img src="/assets/images/social/mail.svg" alt="mail"></a>
              <p class="ms-3 mt-1 text-primary fw-bolder d-none d-lg-block">E-mail <br>
                <span class="text-black fw-lighter">contact&#64;opale.care</span></p>
            </div>
            <div class="w-lg-40 d-flex justify-content-start m-2">
              <a href="https://www.instagram.com/opale.care/" target="_blank"><img src="/assets/images/social/instagram.svg" alt="instagram"></a>
              <p class="ms-3 mt-1 text-primary fw-bolder d-none d-lg-block">Instagram <br>
                <span class="text-black fw-lighter">opale.care</span></p>
            </div>
            <div class="w-lg-40 d-flex justify-content-start m-2">
              <a href="https://www.linkedin.com/company/opale-care/" target="_blank"><img src="/assets/images/social/linkedin.svg" alt="linkedin"></a>
              <p class="ms-3 mt-1 text-primary fw-bolder d-none d-lg-block">Linkedin <br>
                <span class="text-black fw-lighter">Opale.care</span></p>
            </div>
            <div class="w-lg-40 d-flex justify-content-start m-2">
              <a href="https://www.facebook.com/p/Opalecare-61558321136242/" target="_blank"><img src="assets/images/social/facebook.svg" alt="facebook"></a>
              <p class="ms-3 mt-1 text-primary fw-bolder d-none d-lg-block">Facebook <br>
                <span class="text-black fw-lighter">Opale.care</span></p>
            </div>
          </div>
        </div>
        <div>
          <h2 class="text-primary text-center text-lg-start">Suivez nos actualités !</h2>
          <p class="text-center text-lg-start">Vous pouvez vous inscrire à notre newsletter pour professionnels ou particuliers.</p>
          <div class="bg-light shadow p-4">
            <div class="d-flex justify-content-center mb-4 mx-auto">
              <button [ngClass]="{'btn-primary' : newsletterType === 'professional', 'btn-white' : newsletterType !== 'professional'}" class="btn btn-lg btn-white rounded mx-2" id="btn-professional" (click)="changeNewsletterType('professional')">Professionnel</button>
              <button [ngClass]="{'btn-primary' : newsletterType === 'individual', 'btn-white' : newsletterType !== 'individual'}" class="btn btn-lg btn-primary rounded mx-2" id="btn-individual" (click)="changeNewsletterType('individual')">Particulier</button>
              <input type="hidden" id="newsletter-type" value="individual">
            </div>
            <form [formGroup]="newsletterForm" (submit)="onNewsletterSubmit()">
              <div id="notification-newsletter" class="notification success" *ngIf="newsletterSuccess">
                Abonnement réussi !
              </div>
              <label class="text-primary fw-bolder mb-1">Adresse e-mail</label>
              <input type="email" class="form-control mb-3" placeholder="exemple@gmail.com" formControlName="email" email>
              <button class="btn btn-info text-white" [disabled]="newsletterForm.invalid">S'abonner</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <h2 class="text-primary text-center text-lg-start">Une question ?</h2>
      <p class="text-center text-lg-start">Vous pouvez maintenant utiliser le formulaire de contact ou nous écrire à <span class="text-info">contact&#64;opale.care</span>.</p>
      <div class="bg-light p-4 text-primary fw-bolder rounded shadow">

        <div id="notification-contact" class="notification success" *ngIf="contactSuccess">
          Message envoyé
        </div>

        <form [formGroup]="contactForm" (submit)="onContactSubmit()">
          <div class="form-group">
            <label class="mb-3">Nom (facultatif)</label>
            <input type="text" class="form-control mb-4" formControlName="name">
          </div>

          <div class="form-group">
            <label class="mb-3">Adresse e-mail (facultatif)</label>
            <input type="text" class="form-control mb-4" formControlName="email">
          </div>

          <div class="form-group">
            <label class="mb-3">Message</label>
            <textarea class="form-control mb-4" rows="6" formControlName="message"></textarea>
          </div>

          <div class="mt-4">
            <button class="btn btn-info text-white">Envoyer</button>
          </div>
        </form>

      </div>
    </div>
  </div>
</section>
<footer class="text-primary pt-4 pb-2">
  <!-- copyright -->
  <!--<div class="text-center text-sm mt-4 legal-links">
              <a href="">{{ 'Mentions légales' | trans }}</a> •
              <a href="">{{ 'CGV' | trans }}</a> •
              <a href="">{{ 'Confidentialité' | trans }}</a>
          </div> -->
  <div class="text-center text-sm mt-2 fw-bold">
    <span>{{ year }}</span> -
    <a class="text-primary text-decoration-none" href="">Opale</a>
  </div>
</footer>
