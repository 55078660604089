<div class="media">
  <div class="media-body align-self-center">
    <div class="progress-bar shadow-internal">
      <div class="bar bg-primary" [style.width]="getPercent()"></div>
    </div>
  </div>
  <div class="ml-3 align-self-center nunito">
    {{ getPercent() }}
  </div>
</div>
