import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  constructor(
    private http: HttpClient
  ) { }

  getApiEndpoint() {
    return `${environment.api.endpoint}fr/`;
  }

  postNewsletter(params: any): Observable<string>  {
    return this.http.post<string>(`${environment.wwwEndpoint}fr/newsletter`, params)
  }

  postContact(params: any): Observable<string>  {
    return this.http.post<string>(`${environment.wwwEndpoint}fr/contact`, params)
  }
}
