import { Component } from '@angular/core';
import {environment} from "../../../environments/environment";
import {NgClass, NgIf} from "@angular/common";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ToolsService} from "../../services/tools/tools.service";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    NgClass,
    ReactiveFormsModule,
    NgIf
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent  {

  year: number;
  url: string;
  newsletterType: string = 'individual';

  newsletterSuccess= false;
  contactSuccess= false;

  newsletterForm: FormGroup;
  contactForm: FormGroup;

  constructor(
    private toolsSrv: ToolsService
  ) {
    this.year = new Date().getFullYear();
    this.url = environment.wwwEndpoint;

    this.newsletterForm = new FormGroup({
      email: new FormControl(null, [Validators.required]),
      newsletter_type: new FormControl(this.newsletterType),
    });

    this.contactForm = new FormGroup({
      name: new FormControl(null),
      email: new FormControl(null),
      message: new FormControl(null, [Validators.required])
    });
  }

  changeNewsletterType(type: string): void {
    this.newsletterType = type;
    this.newsletterForm.value.newsletter_type = type;
    this.newsletterSuccess = false;
  }

  onNewsletterSubmit(): void {
    if (this.newsletterSuccess) {
      return;
    }
    this.toolsSrv.postNewsletter(this.newsletterForm.value).subscribe(
      (data) => {
        this.newsletterSuccess = true;
      }
    );
  }

  onContactSubmit(): void {
    if (this.contactSuccess) {
      return;
    }
    this.toolsSrv.postContact(this.contactForm.value).subscribe(
      (data) => {
        this.contactSuccess = true;
      }
    );
  }
}
