import { Component, Input } from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {AuthService} from "../../../services/auth/auth.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-signup-modal',
  templateUrl: './signup-modal.component.html',
  styleUrl: './signup-modal.component.scss'
})
export class SignupModalComponent {

  @Input() endSignup?: () => void;

  form: FormGroup;
  errorConfirmPassword: boolean = false;

  survey!: string;

  constructor(
    private modalRef: BsModalRef,
    private authSrv: AuthService
  ) {
    this.form = new FormGroup({
      password: new FormControl(null, [Validators.required]),
      passwordConfirm: new FormControl(null, [Validators.required]),
    });
  }

  closeModal(): void {
    this.modalRef.hide();
  }

  formIsInvalid(): boolean {
    return (this.form.invalid || this.form.value.password !== this.form.value.passwordConfirm);
  }

  samePassword(): void {
    if (this.form.value.password !== this.form.value.passwordConfirm) {
      this.errorConfirmPassword = true;
      return;
    }

    this.errorConfirmPassword = false;
  }

  submit(): void {
    this.authSrv.signup({
      survey: this.survey,
      password: this.form.value.password
    }).subscribe(
      data => this.success(data),
      (error) => {},
      () => {
        this.endSignup?.();
      }
    );
  }

  success(data: any) {
    this.authSrv.saveLogin(data.login);
    this.authSrv.saveToken(data.token);
  }
}
