import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FooterComponent} from "../components/footer/footer.component";
import {NavbarComponent} from "../components/navbar/navbar.component";
import { HttpClientModule } from '@angular/common/http';
import {ProgressBarComponent} from "../components/progress/progress-bar/progress-bar.component";
import {ModalModule} from "ngx-bootstrap/modal";
import {InfoModalComponent} from "../components/modals/info-modal/info-modal.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ToastrModule} from "ngx-toastr";
import {SignupModalComponent} from "../components/modals/signup-modal/signup-modal.component";

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    FooterComponent,
    NavbarComponent,
    ProgressBarComponent,
    InfoModalComponent
  ],
  declarations: [
    SignupModalComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    ProgressBarComponent,
    InfoModalComponent,
    SignupModalComponent
  ]
})
export class SharedModule { }
