<div class="survey mb-3 py-5">
  <div class="container survey-content">
    <div *ngIf="start" class="text-center rounded-5 border-primary">
      <h2 class="text-center important" translate>Important !</h2>
      <p translate>Ce questionnaire nécessite environ 15 minutes de votre temps. Nous vous recommandons de vous assurer d'avoir un moment calme et de rester concentré(e) pour répondre avec précision.</p>
      <a data-aos="fade-up" data-aos-duration="1100" class="btn btn-info text-white btn-lg rounded-pill px-3 py-3 fw-bolder mb-4 mx-auto px-3" (click)="startSurvey()" translate>Commencer</a>
    </div>
    <ng-container *ngIf="!start">
      <div class="text-center">
        <div class="spinner mx-auto" *ngIf="!currentQuestion"></div>
      </div>
      <ng-container *ngIf="currentQuestion">
        <div class="w-50 mx-auto">
          <app-progress-bar [percent]="getProgression()"></app-progress-bar>
        </div>
        <h2 class="text-center question text-primary" *ngIf="currentQuestion">{{ currentQuestion.content }}</h2>
        <div class="flex-column flex-md-row d-flex align-items-md-center justify-content-md-center">
          <button class="btn btn-white shadow text-black btn-lg rounded mx-4 px-4 my-2" *ngFor="let answer of currentQuestion?.answers" (click)="next(answer)">{{ answer.content }}</button>
        </div>
        <div class="card-example text-center">
          <h3 class="text-center text-info mb-4 fw-bold" *ngIf="currentQuestion && currentQuestion.examples && currentQuestion.examples.length > 0">Exemples</h3>
          <ul class="text-start w-50 mx-auto">
            <li class="mb-2 " *ngFor="let example of currentQuestion?.examples">{{ example.content }}</li>
          </ul>
        </div>
        <div class="text-center mb-5">
          <button class="btn btn-light text-info rounded px-2 d-flex align-items-center mx-auto d-flex align-items-center justify-content-center" *ngIf="!currentQuestion.isFirst" (click)="previous()"><span class="material-icons">chevron_left</span></button>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
