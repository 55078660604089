<div class="login mb-3 pt-5">
  <div class="container text-center login-content">
      <form [formGroup]="form" (submit)="onSubmit()" class="w-50 mx-auto">
        <div class="form-group mb-4 text-start">
          <label class="fw-bolder text-primary mb-2">Identifiant</label>
          <input type="text" class="form-control rounded" formControlName="login" id="login" placeholder="ExempleLogin76">
        </div>
        <div class="form-group mb-4 text-start">
          <label class="fw-bolder text-primary mb-2">Mot de passe</label>
          <input type="password" class="form-control rounded" id="password" formControlName="password" placeholder="*****">
        </div>
        <button class="btn btn-lg btn-info text-white rounded shadow mb-2" [disabled]="form.invalid || loading" translate>Se connecter</button>
        <p class="text-muted mt-3">Vous n'avez pas de compte ? <a class="text-primary text-decoration-none fw-bolder" href="/">Faites le test</a></p>
      </form>
  </div>
</div>
